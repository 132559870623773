// see https://github.com/gatsbyjs/gatsby/issues/7810
module.exports = {
  SECTIONS: [
    {
      title: 'Finance Calculators',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'rise-line',
      idSection: 'finance-calculators',
      bgImage: 'finance-calculator'
    },
    {
      title: 'Construction',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'hammer',
      idSection: 'construction',
      bgImage: 'constructions'
    },
    {
      title: 'Grade',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'grade',
      idSection: 'grade',
      bgImage: 'grade-calculators'
    },
    {
      title: 'Fitness & Health',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'dumbbell',
      idSection: 'fitness--health',
      bgImage: 'health-and-fitness'
    },
    {
      title: 'Conversion',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'filter',
      idSection: 'conversion',
      bgImage: 'conversions'
    },
    {
      title: 'Online Tools',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'grade',
      idSection: 'online-tools',
      bgImage: 'online-tools'
    },
    {
      title: 'Math',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'cube',
      idSection: 'math',
      bgImage: 'math-calculators'
    },
    {
      title: 'Everyday Life',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'couch',
      idSection: 'everyday-life',
      bgImage: 'everyday-life'
    }
  ],
  CATEGORIES: {
    'finance-calculators': {
      title: 'Finance Calculators',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'rise-line',
      idSection: 'finance-calculators',
      bgImage: 'finance-calculator',
      calculators: [
        {
          id: 'paypal',
          title: 'PayPal Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'ebay',
          title: 'Ebay',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'stripe',
          title: 'Stripe',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        }
      ],
      subCategories: {
        'business-planning': {
          label: 'Business Planning Calculators',
          icon: 'business-planning',
          id: 'business-planning',
          disabled: true
        },
        investment: {
          label: 'Investment Calculators',
          icon: 'investment',
          id: 'investment',
          disabled: true
        },
        sales: {
          label: 'Sales Calculators',
          icon: 'sales',
          id: 'sales',
          disabled: false,
          calculators: [
            {
              id: 'paypal',
              title: 'PayPal Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            },
            {
              id: 'ebay',
              title: 'Ebay',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            },
            {
              id: 'stripe',
              title: 'Stripe',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        tax: {
          label: 'Tax And Salary Calculators',
          icon: 'tax',
          id: 'tax',
          disabled: true
        },
        'real-state': {
          label: 'Real Estate Calculators',
          icon: 'real-state',
          id: 'real-state',
          disabled: true
        },
        'personal-finance': {
          label: 'Personal Finance Calculators',
          icon: 'personal-finance',
          id: 'personal-finance',
          disabled: true
        },
        'debt-management': {
          label: 'Debt Management Calculators',
          icon: 'debt-management',
          id: 'debt-management',
          disabled: true
        }
      }
    },
    construction: {
      title: 'Construction',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'hammer',
      idSection: 'construction',
      bgImage: 'constructions',
      subCategories: {
        'construction-converter': {
          label: 'Construction Converters',
          icon: 'construction-converter',
          id: 'construction-converter',
          disabled: true
        },
        'construction-materials': {
          label: 'Construction Materials Calculators',
          icon: 'construction-materials',
          id: 'construction-materials',
          disabled: true
        },
        'home-and-garden': {
          label: 'Home And Garden Calculators',
          icon: 'home-and-garden',
          id: 'home-and-garden',
          disabled: true
        },
        roofing: {
          label: 'Roofing Calculators',
          icon: 'roofing',
          id: 'roofing',
          disabled: true
        },
        driveway: {
          label: 'Driveway Calculators',
          icon: 'driveway',
          id: 'driveway',
          disabled: true
        }
      }
    },
    grade: {
      title: 'Grade',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'grade',
      idSection: 'grade',
      bgImage: 'grade-calculators',
      calculators: [
        {
          id: 'final-grade',
          title: 'Final Grade Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'gpa',
          title: 'GPA Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'gpa-to-letter',
          title: 'GPA to Letter Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'grade',
          title: 'Grade Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'high-school-gpa',
          title: 'High School GPA Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'letter-to-grade',
          title: 'Letter to Grade Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'test',
          title: 'Test Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        }
      ],
      subCategories: {
        'college-gpa': {
          label: 'College GPA Calculator',
          icon: 'college-gpa',
          id: 'college-gpa',
          disabled: false,
          calculators: [
            {
              id: 'gpa',
              title: 'GPA Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        'final-grade': {
          label: 'Final Grade Calculator',
          icon: 'final-grade',
          id: 'final-grade',
          disabled: false,
          calculators: [
            {
              id: 'final-grade',
              title: 'Final Grade Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            },
            {
              id: 'grade',
              title: 'Grade Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        'gpa-to-letter': {
          label: 'GPA To Letter Grade Conversion',
          icon: 'gpa',
          id: 'gpa-to-letter',
          disabled: false,
          calculators: [
            {
              id: 'gpa-to-letter',
              title: 'GPA to Letter Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        'high-school-gpa': {
          label: 'High School GPA Calculator',
          icon: 'high-school-gpa',
          id: 'high-school-gpa',
          disabled: false,
          calculators: [
            {
              id: 'high-school-gpa',
              title: 'High School GPA Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        'letter-to-gpa': {
          label: 'Letter Grade To GPA Conversion',
          icon: 'gpa',
          id: 'letter-to-gpa',
          disabled: false,
          calculators: [
            {
              id: 'letter-to-grade',
              title: 'Letter to Grade Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        test: {
          label: 'Test Calculator',
          icon: 'test',
          id: 'test',
          disabled: false,
          calculators: [
            {
              id: 'test',
              title: 'Test Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        }
      }
    },
    'fitness--health': {
      title: 'Fitness & Health',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'dumbbell',
      idSection: 'fitness--health',
      bgImage: 'health-and-fitness',
      subCategories: {
        'body-measurement': {
          label: 'Body Measurements Calculators',
          icon: 'body-measurement',
          id: 'body-measurement',
          disabled: true
        },
        dietary: {
          label: 'Dietary Calculators',
          icon: 'dietary',
          id: 'dietary',
          disabled: true
        },
        'metabolic-disorders': {
          label: 'Metabolic Disorders Calculators',
          icon: 'metabolic-disorders',
          id: 'metabolic-disorders',
          disabled: true
        },
        'fertility-pregnancy': {
          label: 'Fertility & Pregnancy Calculators',
          icon: 'fertility-pregnancy',
          id: 'fertility-pregnancy',
          disabled: true
        },
        addiction: {
          label: 'Addiction Medicine Calculators',
          icon: 'addiction',
          id: 'addiction',
          disabled: true
        },
        cardiovascular: {
          label: 'Cardiovascular System Calculators',
          icon: 'cardiovascular',
          id: 'cardiovascular',
          disabled: true
        },
        electrolytes: {
          label: 'Electrolytes & FLuids Calculators',
          icon: 'electrolytes',
          id: 'electrolytes',
          disabled: true
        },
        hematology: {
          label: 'Hematology Calculators',
          icon: 'hematology',
          id: 'hematology',
          disabled: true
        },
        pediatric: {
          label: 'Pediatric Calculators',
          icon: 'pediatric',
          id: 'pediatric',
          disabled: true
        },
        percentile: {
          label: 'Percentile Calculators',
          icon: 'percentile',
          id: 'percentile',
          disabled: true
        },
        pulmonary: {
          label: 'Pulmonary Calculators',
          icon: 'pulmonary',
          id: 'pulmonary',
          disabled: true
        },
        'general-health': {
          label: 'General Health Calculators',
          icon: 'general-health',
          id: 'general-health',
          disabled: true
        }
      }
    },
    conversion: {
      title: 'Conversion',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'filter',
      idSection: 'conversion',
      bgImage: 'conversions',
      subCategories: {
        sales: {
          label: 'Conversion Calculators',
          icon: 'sales',
          id: 'sales',
          disabled: true
        }
      }
    },
    'online-tools': {
      title: 'Online Tools',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'grade',
      idSection: 'online-tools',
      bgImage: 'online-tools',
      subCategories: {
        'note-pad': {
          label: 'Note Pad Online',
          icon: 'note-pad',
          id: 'note-pad',
          disabled: true
        },
        'online-clock': {
          label: 'Online Clock',
          icon: 'online-clock',
          id: 'online-clock',
          disabled: true
        },
        'ip-subnet': {
          label: 'IP Subnet Calculator',
          icon: 'ip-subnet',
          id: 'ip-subnet',
          disabled: true
        },
        password: {
          label: 'Password Generator',
          icon: 'password',
          id: 'password',
          disabled: true
        },
        bandwidth: {
          label: 'Bandwith Calculator',
          icon: 'bandwidth',
          id: 'bandwidth',
          disabled: true
        },
        'dice-roller': {
          label: 'Dice Roller',
          icon: 'dice-roller',
          id: 'dice-roller',
          disabled: true
        },
        'video-downloader': {
          label: 'Video Downloader',
          icon: 'video-downloader',
          id: 'video-downloader',
          disabled: true
        }
      }
    },
    math: {
      title: 'Math',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'cube',
      idSection: 'math',
      bgImage: 'math-calculators',
      calculators: [
        {
          id: 'derivative',
          title: 'Derivative Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        },
        {
          id: 'integral',
          title: 'Integral Calculator',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
        }
      ],
      subCategories: {
        percentages: {
          label: 'Percentages Calculators',
          icon: 'percentages',
          id: 'percentages',
          disabled: true
        },
        algebra: {
          label: 'Algebra Calculators',
          icon: 'algebra',
          id: 'algebra',
          disabled: false,
          calculators: [
            {
              id: 'derivative',
              title: 'Derivative Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            },
            {
              id: 'integral',
              title: 'Integral Calculator',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation.'
            }
          ]
        },
        arithmetic: {
          label: 'Arithmetic Calculators',
          icon: 'arithmetic',
          id: 'arithmetic',
          disabled: true
        },
        coordinate: {
          label: 'Coordinate Calculators',
          icon: 'coordinate',
          id: 'coordinate',
          disabled: true
        },
        trigonometry: {
          label: 'Trigonometry Calculators',
          icon: 'trigonometry',
          id: 'trigonometry',
          disabled: true
        },
        'geometry-2d': {
          label: '2D Geometry Calculators',
          icon: 'geometry-2d',
          id: 'geometry-2d',
          disabled: true
        },
        'geometry-3d': {
          label: '3D Geometry Calculators',
          icon: 'geometry-3d',
          id: 'geometry-3d',
          disabled: true
        },
        sequences: {
          label: 'Sequences Calculators',
          icon: 'sequences',
          id: 'sequences',
          disabled: true
        },
        more: {
          label: 'Other Calculators',
          icon: 'more',
          id: 'more',
          disabled: true
        }
      }
    },
    'everyday-life': {
      title: 'Everyday Life',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      icon: 'couch',
      idSection: 'everyday-life',
      bgImage: 'everyday-life',
      subCategories: {
        'every-day': {
          label: 'Everyday Savings Calculators',
          icon: 'every-day',
          id: 'every-day',
          disabled: true
        },
        transportation: {
          label: 'Transportation Calculators',
          icon: 'transportation',
          id: 'transportation',
          disabled: true
        },
        'sales-day': {
          label: 'Sales Calculators',
          icon: 'sales-day',
          id: 'sales-day',
          disabled: true
        },
        'tax-day': {
          label: 'Tax And Salary Calculators',
          icon: 'tax-day',
          id: 'tax-day',
          disabled: true
        },
        'real-state-day': {
          label: 'Real Estate Calculators',
          icon: 'real-state-day',
          id: 'real-state-day',
          disabled: true
        },
        'personal-financeDay': {
          label: 'Personal Finance Calculators',
          icon: 'personal-financeDay',
          id: 'personal-financeDay',
          disabled: true
        },
        'debt-managementDay': {
          label: 'Debt Management Calculators',
          icon: 'debt-managementDay',
          id: 'debt-managementDay',
          disabled: true
        }
      }
    }
  }
}
