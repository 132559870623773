import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { string, func } from 'prop-types'

import { grey, black } from '@/style-guide/colors'
import media from 'styled-media-query'

import couch from '@/assets/icons/couch.svg'
import cube from '@/assets/icons/cube.svg'
import dumbbell from '@/assets/icons/dumbbell.svg'
import filter from '@/assets/icons/filter.svg'
import grade from '@/assets/icons/grade.svg'
import hammer from '@/assets/icons/hammer.svg'
import riseLine from '@/assets/icons/rise-line.svg'

const ContainerLink = styled(Link)`
  text-decoration: none;
`

const ContainerAnchor = styled.a`
  text-decoration: none;
`

const Content = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;

  :hover {
    opacity: 0.8;
  }

  ${media.greaterThan('medium')`
    align-items: unset;
    padding: 20px;
  `}
`

const Title = styled.p`
  font-size: 14.5201px;
  font-weight: bold;
  line-height: 18px;
  color: ${black};
  margin-top: 20px;
  margin: 0px;
`

const Description = styled.span`
  display: none;
  font-size: 12px;
  line-height: 22px;
  color: ${grey};

  ${media.greaterThan('medium')`
    display: block;
  `}
`

const IconCol = styled.div`
  background: #f1f9fd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0px 10px;
  width: 50px;
  height: 50px;
`

const TextCol = styled.div`
  width: 80%;
`

const icons = {
  couch,
  cube,
  dumbbell,
  filter,
  grade,
  hammer,
  'rise-line': riseLine
}

const Option = ({ title, description, icon, handleToggleVisible, idSection, type }) => {
  const content = (
    <Content>
      <IconCol>
        <img src={icons[icon]} alt={icon} width={30} height={30} />
      </IconCol>
      <TextCol>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextCol>
    </Content>
  )

  if (type === 'href') {
    return (
      <ContainerAnchor href={`#${idSection}`} onClick={handleToggleVisible}>
        {content}
      </ContainerAnchor>
    )
  }

  return (
    <ContainerLink to={`/calculator-categories/${idSection}`} onClick={handleToggleVisible}>
      {content}
    </ContainerLink>
  )
}

Option.propTypes = {
  title: string,
  description: string,
  icon: string,
  idSection: string,
  type: string,
  handleToggleVisible: func
}

export default Option
