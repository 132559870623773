import React, { useState } from 'react'
import { node, bool, object, string } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { SECTIONS } from '@/helpers/mocks/template'

import { white } from '@/style-guide/colors'

import Option from './Option'
import Locales from './Locales'
import CardCoffee from '../CardCoffee'
import Socials from '../Socials'

const Container = styled.div`
  ${media.greaterThan('medium')`
    position: relative;
  `}
`

const Dropdown = styled.div`
  width: 100%;
  cursor: pointer;
`

const Content = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  top: 25px;
  position: fixed;
  z-index: 5;
  background: ${white};
  width: 90%;
  right: 5%;
  box-sizing: border-box;
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
  border-radius: 6px;

  ${media.greaterThan('medium')`
    display: ${props => (props.visible ? 'grid' : 'none')};
    grid-template-columns: auto auto auto;
    top: 55px;
    left: 0;
    width: 1095px;
    position: absolute;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  `}
`

const Mask = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${media.greaterThan('medium')`
    background: rgba(0, 0, 0, 0);
  `}
`

const StyledSocials = styled(Socials)`
  display: flex;
  justify-content: space-around;
  background: #f6f6f6;
  padding: 10px;
  margin: 15px 0px;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

const CategoriesMenu = ({ action, footer = true, style, type = 'link' }) => {
  const [visible, setVisible] = useState('')

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <Container style={style}>
      <Dropdown onClick={handleToggleVisible}>{action}</Dropdown>

      <Content visible={visible}>
        {SECTIONS.map((item, index) => (
          <Option
            key={index}
            index={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
            handleToggleVisible={handleToggleVisible}
            idSection={item.idSection}
            type={type}
          />
        ))}
        <CardCoffee
          style={{
            margin: '0px 20px 20px 20px',
            width: 'auto'
          }}
        />
        {footer && (
          <>
            <StyledSocials />
            <Locales />
          </>
        )}
      </Content>
      <Mask onClick={handleToggleVisible} visible={visible} />
    </Container>
  )
}

CategoriesMenu.propTypes = {
  footer: bool,
  type: string,
  action: node.isRequired,
  style: object
}

export default CategoriesMenu
