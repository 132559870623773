import React from 'react'

export const SECTIONS = [
  {
    title: 'Finance Calculators',
    description:
      'It doesn’t matter whether you are the CEO of Google, a venture capitalist, a stockbroker, an entrepreneur or a simple student - we all have to admit that this world revolves around money.',
    image: require('@/assets/lottie-images/finance.json'),
    idSection: 'finance-calculators',
    items: [
      {
        label: 'Business Planning Calculators',
        icon: 'business-planning',
        id: 'business-planning',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: 'Investment Calculators',
        icon: 'investment',
        id: 'investment',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: 'Sales Calculators',
        icon: 'sales',
        id: 'sales',
        parent: 'finance-calculators',
        disabled: false
      },
      {
        label: 'Tax And Salary Calculators',
        icon: 'tax',
        id: 'tax',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: 'Real Estate Calculators',
        icon: 'real-state',
        id: 'real-state',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: 'Personal Finance Calculators',
        icon: 'personal-finance',
        id: 'personal-finance',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: 'Debt Management Calculators',
        icon: 'debt-management',
        id: 'debt-management',
        parent: 'finance-calculators',
        disabled: true
      },
      {
        label: (
          <span>
            <strong>3+</strong> Calculators
          </span>
        ),
        icon: 'more',
        parent: 'finance-calculators',
        disabled: true
      }
    ]
  },
  {
    title: 'Fitness & health',
    description:
      'Welcome to health calculators! Whether you are a doctor, a medical student or a patient, you will find answers to your medical questions here, as well as receive a lot of scientifically proven information.',
    image: require('@/assets/lottie-images/fitness.json'),
    idSection: 'fitness--health',
    inverted: true,
    items: [
      {
        label: 'Body Measurements Calculators',
        icon: 'body-measurement',
        id: 'body-measurement',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Dietary Calculators',
        icon: 'dietary',
        id: 'dietary',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Metabolic Disorders Calculators',
        icon: 'metabolic-disorders',
        id: 'metabolic-disorders',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Fertility & Pregnancy Calculators',
        icon: 'fertility-pregnancy',
        id: 'fertility-pregnancy',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Addiction Medicine Calculators',
        icon: 'addiction',
        id: 'addiction',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Cardiovascular System Calculators',
        icon: 'cardiovascular',
        id: 'cardiovascular',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: 'Electrolytes & Fluids Calculators',
        icon: 'electrolytes',
        id: 'electrolytes',
        parent: 'fitness--health',
        disabled: true
      },
      {
        label: (
          <span>
            <strong>5+</strong> Calculators
          </span>
        ),
        icon: 'more',
        parent: 'fitness--health',
        disabled: true
      }
    ]
  },
  {
    title: 'Math',
    description:
      'It doesn’t matter whether you are the CEO of Google, a venture capitalist, a stockbroker, an entrepreneur or a simple student - we all have to admit that this world revolves around money.',
    image: require('@/assets/lottie-images/math.json'),
    idSection: 'math',
    items: [
      {
        label: 'Percentages Calculators',
        icon: 'percentages',
        id: 'percentages',
        parent: 'math',
        disabled: true
      },
      {
        label: 'Algebra Calculators',
        icon: 'algebra',
        id: 'algebra',
        parent: 'math',
        disabled: false
      },
      {
        label: 'Arithmetic Calculators',
        icon: 'arithmetic',
        id: 'arithmetic',
        parent: 'math',
        disabled: true
      },
      {
        label: 'Coordinate Geometry Calculators',
        icon: 'coordinate',
        id: 'coordinate',
        parent: 'math',
        disabled: true
      },
      {
        label: 'Trigonometry Calculators',
        icon: 'trigonometry',
        id: 'trigonometry',
        parent: 'math',
        disabled: true
      },
      {
        label: '2D Geometry Calculators',
        icon: 'geometry-2d',
        id: 'geometry-2d',
        parent: 'math',
        disabled: true
      },
      {
        label: '3D Geometry Calculators',
        icon: 'geometry-3d',
        id: 'geometry-3d',
        parent: 'math',
        disabled: true
      },
      {
        label: 'Sequences Calculators',
        icon: 'more',
        id: 'more',
        parent: 'math',
        disabled: true
      }
    ]
  },
  {
    title: 'Grade',
    description:
      'Welcome to health calculators! Whether you are a doctor, a medical student or a patient, you will find answers to your medical questions here, as well as receive a lot of scientifically proven information.',
    image: require('@/assets/lottie-images/grade.json'),
    idSection: 'grade',
    inverted: true,
    items: [
      {
        label: 'College GPA Calculator',
        icon: 'college-gpa',
        id: 'college-gpa',
        parent: 'grade',
        disabled: false
      },
      {
        label: 'Final Grade Calculator',
        icon: 'final-grade',
        id: 'final-grade',
        parent: 'grade',
        disabled: false
      },
      {
        label: 'GPA To Letter Grade Conversion',
        icon: 'gpa',
        id: 'gpa-to-letter',
        parent: 'grade',
        disabled: false
      },
      {
        label: 'High School GPA Calculator',
        icon: 'high-school-gpa',
        id: 'high-school-gpa',
        parent: 'grade',
        disabled: false
      },
      {
        label: 'Letter Grade To GPA Conversion',
        icon: 'gpa',
        id: 'letter-to-gpa',
        parent: 'grade',
        disabled: false
      },
      {
        label: 'Test Calculator',
        icon: 'test',
        id: 'test',
        parent: 'grade',
        disabled: false
      }
    ]
  },
  {
    title: 'Construction',
    description:
      'Welcome to health calculators! Whether you are a doctor, a medical student or a patient, you will find answers to your medical questions here, as well as receive a lot of scientifically proven information.',
    image: require('@/assets/lottie-images/construction.json'),
    idSection: 'construction',
    items: [
      {
        label: 'Construction Converters',
        icon: 'construction-converter',
        id: 'construction-converter',
        parent: 'construction',
        disabled: true
      },
      {
        label: 'Construction Materials Calculators',
        icon: 'construction-materials',
        id: 'construction-materials',
        parent: 'construction',
        disabled: true
      },
      {
        label: 'Home And Garden Calculators',
        icon: 'home-and-garden',
        id: 'home-and-garden',
        parent: 'construction',
        disabled: true
      },
      {
        label: 'Roofing Calculators',
        icon: 'roofing',
        id: 'roofing',
        parent: 'construction',
        disabled: true
      },
      {
        label: 'Driveway Calculators',
        icon: 'driveway',
        id: 'driveway',
        parent: 'construction',
        disabled: true
      },
      {
        label: (
          <span>
            <strong>1+</strong> Calculators
          </span>
        ),
        icon: 'more',
        parent: 'construction',
        disabled: true
      }
    ]
  },
  {
    title: 'Conversion',
    description:
      'This pack of conversion calculators has been conscientiously prepared to serve you in almost every aspect of your life. They work both on the computer and on the smartphone so you can use them whenever and wherever you want.',
    image: require('@/assets/lottie-images/conversion.json'),
    idSection: 'conversion',
    inverted: true,
    items: [
      {
        label: 'Conversion Calculators',
        icon: 'sales',
        id: 'sales',
        parent: 'conversion',
        disabled: true
      }
    ]
  },
  {
    title: 'Everyday Life',
    description:
      'It doesn’t matter whether you are the CEO of Google, a venture capitalist, a stockbroker, an entrepreneur or a simple student - we all have to admit that this world revolves around money',
    image: require('@/assets/lottie-images/everyday-life.json'),
    idSection: 'everyday-life',
    items: [
      {
        label: 'Everyday Savings Calculators',
        icon: 'every-day',
        id: 'every-day',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Transportation Calculators',
        icon: 'transportation',
        id: 'transportation',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Sales Calculators',
        icon: 'sales-day',
        id: 'sales-day',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Tax And Salary Calculators',
        icon: 'tax-day',
        id: 'tax-day',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Real Estate Calculators',
        icon: 'real-state-day',
        id: 'real-state-day',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Personal Finance Calculators',
        icon: 'personal-financeDay',
        id: 'personal-financeDay',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: 'Debt Management Calculators',
        icon: 'debt-managementDay',
        id: 'debt-managementDay',
        parent: 'everyday-life',
        disabled: true
      },
      {
        label: (
          <span>
            <strong>3+</strong> Calculators
          </span>
        ),
        icon: 'more',
        parent: 'everyday-life',
        disabled: true
      }
    ]
  },
  {
    title: 'Online Tools',
    description:
      'It doesn’t matter whether you are the CEO of Google, a venture capitalist, a stockbroker, an entrepreneur or a simple student - we all have to admit that this world revolves around money',
    image: require('@/assets/lottie-images/online-tools.json'),
    idSection: 'online-tools',
    inverted: true,
    items: [
      {
        label: 'Note Pad Online',
        icon: 'note-pad',
        id: 'note-pad',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'Online Clock',
        icon: 'online-clock',
        id: 'online-clock',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'IP Subnet Calculator',
        icon: 'ip-subnet',
        id: 'ip-subnet',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'Password Generator',
        icon: 'password',
        id: 'password',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'Bandwith Calculator',
        icon: 'bandwidth',
        id: 'bandwidth',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'Dice Roller',
        icon: 'dice-roller',
        id: 'dice-roller',
        parent: 'online-tools',
        disabled: true
      },
      {
        label: 'Video Downloader',
        icon: 'video-downloader',
        id: 'video-downloader',
        parent: 'online-tools',
        disabled: true
      }
    ]
  }
]

export const FEATUREDS = [
  {
    name: 'The Guardian',
    image: require('@/assets/images/the-guardian.svg')
  },
  {
    name: 'Tech Crumch',
    image: require('@/assets/images/tech-crumch.svg')
  },
  {
    name: 'Wired',
    image: require('@/assets/images/wired.svg')
  },
  {
    name: 'Forbes',
    image: require('@/assets/images/forbes.svg')
  },
  {
    name: 'Business Insider',
    image: require('@/assets/images/business-insider.svg')
  },
  {
    name: 'TNW',
    image: require('@/assets/images/tnw.svg')
  }
]

export const RECENT_ARTICLES = [
  {
    title: 'What Do the 2019 Tax Rates Mean for You?',
    text:
      "The Internal Revenue Service (IRS) recently published the 2019 inflation amendments for various tax provisions, such as cost of living and the tax rate tables. If you're wondering how these adjustments will impact you and more and more text to view here, do you wanna see everything here? Testing",
    image: require('@/assets/images/article-1.svg')
  },
  {
    title: "Trump's 2018 Tax Reforms and More and More Text to Broken",
    text:
      "Just before Christmas last year, the Tax Cuts and Jobs Act was signed by Trump. The Act is the landmark legal measure of the President's first year in office and represents the most significant change to the tax code for over 30 years.",
    image: require('@/assets/images/article-2.svg')
  },
  {
    title: 'Student Loans & Payments: More refuns and Testing Text',
    text:
      "Have you recently attended college? If you have, or are thinking about starting, there's one big worry on your mind: student loans. These loans climb up fast and aren't easy to repay to test and everything inside here is a text, just a test, thanks.",
    image: require('@/assets/images/article-3.svg')
  }
]
