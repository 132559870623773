import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black } from '@/style-guide/colors'

import spain from '@/assets/icons/spain.svg'
import usa from '@/assets/icons/usa.svg'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

const Col = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  font-size: 14.5201px;
  font-weight: bold;
  line-height: 18px;
  color: ${black};
`

const Img = styled.img`
  margin-right: 10px;
`

const Locales = () => (
  <Container>
    <Col>
      <Img src={usa} alt="USA" />
      <Text>ENGLISH</Text>
    </Col>
    <Col>
      <Img src={spain} alt="Spain" />
      <Text>SPANISH</Text>
    </Col>
  </Container>
)

export default Locales
