import React from 'react'
import styled from 'styled-components'
import { object, string } from 'prop-types'

import facebook from '@/assets/icons/facebook.svg'
import linkedin from '@/assets/icons/linkedin.svg'
import twitter from '@/assets/icons/twitter.svg'
import instagram from '@/assets/icons/instagram.svg'

const Container = styled.div``

const Img = styled.img`
  cursor: pointer;
`

const Socials = ({ style, className }) => (
  <Container className={className} style={style}>
    <Img src={facebook} alt="Facebook" />
    <Img src={linkedin} alt="LinkedIn" />
    <Img src={twitter} alt="Twitter" />
    <Img src={instagram} alt="Instagram" />
  </Container>
)

Socials.propTypes = {
  style: object,
  className: string
}

export default Socials
