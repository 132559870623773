import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { bool, object } from 'prop-types'
import { PayPalButton } from 'react-paypal-button-v2'

import coffe from '@/assets/images/coffe.svg'
import tree from '@/assets/icons/tree.svg'
import mdSmile from '@/assets/icons/smile-md.svg'
import lgSmile from '@/assets/icons/smile-lg.svg'
import xlSmile from '@/assets/icons/smile-xl.svg'

import { white } from '@/style-guide/colors'
import { Card, Button as ButtonComponent, RangeSlider } from '@/style-guide'

const Container = styled(Card)`
  display: none;
  min-width: unset;
  border-radius: 8px;
  box-shadow: unset;
  cursor: pointer;
  border: unset;

  ${media.greaterThan('medium')`
    display: flex;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${white};
`

const TextCol = styled.div`
  width: 70%;
  line-height: 10px;
  padding: 30px 20px;
`

const ImgCol = styled.div`
  width: 30%;
`

const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
`

const Text = styled.span`
  font-size: 11px;
  line-height: 10px;
`

const Body = styled.div`
  padding: 0px 20px 14px 20px;
  margin: 20px 14px 0px 14px;
  background: #4662c5;
  border-radius: 8px;
`

const Header = styled.div`
  display: flex;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 15px;
`

const ValueSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4c6bd7;
  border-radius: 4px;
  padding: 0px 10px;
  width: 109px;
  height: 42px;
`

const Value = styled.span`
  font-size: 20px;
  line-height: 14px;
`

const Donate = styled.div`
  width: 150px;
  height: 42px;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  font-size: 11px;
  line-height: 14px;

  > img {
    margin-right: 10px;
  }
`

const ValueActions = styled.div``

const Button = styled(ButtonComponent)`
  width: 23px;
  height: 12px;
  min-width: unset;
  min-height: unset;
  background: ${white};
  color: #4c6bd7;
  border-radius: 2px;
  font-size: 9px;
  font-weight: bold;
  margin: 4px 2px;

  :hover:not(:disabled) {
    background-color: #c3e0f2;
  }
`

const CardCoffe = ({ donateVisible = false, style }) => {
  const [smile, setSmile] = useState(mdSmile)
  const [donation, setDonation] = useState(0)
  const donated = donation + 1

  let cups = Math.round((donation / 25) * 10)
  let hours = 10 - Math.round((donation / 25) * 10)
  let percent = Math.round((donation / 25) * 100)

  if (percent > 100) {
    cups = 10
    percent = 100
    hours = 0
  } else if (percent < 0) {
    cups = 0
    percent = 0
    hours = 10
  }

  const handleDonate = value => {
    setDonation(value)

    if (donation > 18) {
      setSmile(xlSmile)
    } else if (donation > 10) {
      setSmile(lgSmile)
    } else {
      setSmile(mdSmile)
    }
  }

  return (
    <Container
      style={style}
      styleContent={{
        padding: '0px',
        background: 'radial-gradient(50% 50% at 50% 50%, #4472de 0%, #2b59c5 100%)',
        height: '100%'
      }}
      content={
        <Content>
          <Header>
            <TextCol>
              <Title>Buy Us Coffe </Title>
              <Text>so we can stay awake creating Amazing calculators</Text>
            </TextCol>
            <ImgCol>
              <img src={coffe} alt="Calculator" />
            </ImgCol>
          </Header>
          {donateVisible && (
            <>
              <Body>
                <RangeSlider
                  label="Cups of Coffe"
                  max="10"
                  initialValue={0}
                  value={cups}
                  onChangeValue={value => handleDonate(Math.round((value / 10) * 25))}
                />

                <RangeSlider
                  label="Hours of Sleep"
                  max="10"
                  initialValue={0}
                  value={hours}
                  onChangeValue={value => handleDonate(25 - Math.round((value / 10) * 25))}
                />

                <RangeSlider
                  label="Team Happiness"
                  img={smile}
                  max="100"
                  initialValue={0}
                  value={percent}
                  symbol="%"
                  onChangeValue={value => handleDonate(Math.round((value / 100) * 25))}
                />

                <Label>
                  <Text>Donation value($)</Text>
                </Label>
                <Label>
                  <ValueSection>
                    <Value>{donated}$</Value>
                    <ValueActions>
                      <Button
                        label="+"
                        onClick={() => handleDonate(donated === 1000 ? donation : donation + 1)}
                      />
                      <Button
                        label="-"
                        onClick={() => handleDonate(donated === 5 ? donation : donation - 1)}
                      />
                    </ValueActions>
                  </ValueSection>
                  <Donate>
                    <PayPalButton
                      amount={donated}
                      style={{
                        layout: 'horizontal',
                        color: 'white',
                        tagline: false,
                        height: 42,
                        width: 113
                      }}
                      options={{
                        clientId: process.env.GATSBY_PAYPAL_CLIENT_ID
                      }}
                      onSuccess={details => {
                        // eslint-disable-next-line no-alert
                        alert(`Transaction completed by ${details.payer.name.given_name}`)
                      }}
                    />
                  </Donate>
                </Label>
              </Body>
              <Footer>
                <img src={tree} alt="Tree" />
                <span>all money will be donated to charities.</span>
              </Footer>
            </>
          )}
        </Content>
      }
    />
  )
}

CardCoffe.propTypes = {
  donateVisible: bool,
  style: object
}

export default CardCoffe
